.Header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 1em;
    padding-right: 1em;
}

ul {
    list-style-type: none;
    display: flex;
    justify-content: space-around;
  }

li {
    margin-right: 1em;
    text-decoration: none;
    color: gray;
}

li a {
    color: grey;
    text-decoration: none;
}

li a:hover {
    color: darkgrey;
}

.active {
    color: pink;
}