.App {
  height: 100%;
  display: grid;
  grid-template-rows: 10% 80% 10%;
}

.Content {
  background: url(assets/eldtassensdark.png) no-repeat center center;
  -webkit-background-size: 40em;
  -moz-background-size: 40em;
  -o-background-size: 40em;
  background-size: 40em;
  height: 100%;
}

.Container {
  margin: 1em 3em 1em 3em;
  line-height: 25px;
  overflow: scroll;
}

h1 {
  color: orange;
}

p {
  margin-bottom: 3em;
  text-align: justify;
}

img {
  margin-bottom: 3em;
  max-width: 100%;
  height: auto;
}