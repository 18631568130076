.Footer {
  font-size: 1.5rem;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  padding-bottom: 1em;
  background: #323b41;
  color: white;
}

.Footer a {
  color: var(--primary);
  text-decoration: none;
  display: flex;
  align-items: center;
}

.Footer a:hover {
  text-decoration: underline;
}

.FooterLink {
  margin: 0;
  display: flex;
  justify-content: center;
}

#footer-text {
  margin-left: 10px;
}

@media screen and (min-width: 1024px) {}

@media screen and (max-width: 1024px) {
  #footer-text {
    display: none;
  }
}
